import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
    interface Window {
        _paq?: Array<(string | number | boolean | object)[]>;
    }
}

const MatomoTracker = () => {
    const location = useLocation();

    useEffect(() => {
        if (window._paq) {
            window._paq.push(['setCustomUrl', location.pathname + location.search]);
            window._paq.push(['trackPageView']);
        }
    }, [location]);

    return null;
};

export default MatomoTracker;
